<template>
  <span>
    <template v-if="skuInfoList.length">
      <!-- 新size逻辑 -->
      <span
        v-for="(item, index) in skuInfoList"
        :key="index"
      >
        <template v-if="item.attr_id == 87">
           <!-- 新本地化尺码展示 -->
          <template v-if="isNewSpecialSize">
            <!-- 通过接口获取本地化尺码新版 -->
            {{ localSizeTextEvt(item) }}
          </template>
          <template v-else-if="isSpecialSize && locationSize(item.attr_value_name_en)">
            <!-- 新字段区分本地尺码，如有本地尺码替换掉87size尺码 -->
            {{ attriButeValue(item) }}({{ locationSize(item.attr_value_name_en) }})
          </template>
          <template v-else>
            {{ attriButeValue(item) }}
          </template>
        </template>
        <template v-else>
          <!-- 有attribute_value_desc展示attribute_value_sl-attribute_value_desc否则展示attribute_value_sl -->
          {{ attriButeValue(item) }}
        </template>
        <template v-if="skuInfoList.length > (index + 1)">
          <!-- 分隔符，例如skuInfoList返回多条属性，将其中的属性分割开来 -->
          {{ delimiter }}
        </template>
      </span>
    </template>
    <template v-else-if="onlyShowSize">
      {{ language }}: {{ goodsInfo.size }}
    </template>
    <template v-else-if="onlyShowGoodsAttr">
      {{ goods_attr.replace(':', `: ${attr_value_std_name ? attr_value_std_name + delimiter : ''}`) }}
    </template>
    <template v-else>
      <template v-if="goods_attr">
        <!-- 判断是否取多语言isOts=1取多语言 -->
        <template v-if="goodsInfo.isOts">
          {{ language }}
          <template v-if="attr_value_std_name">
            {{ attr_value_std_name }} {{ delimiter }}
          </template>
          {{ goods_attr.includes(':') ? goods_attr.split(':')[1] : goods_attr }}
        </template>
        <template v-else>
          {{ goods_attr.replace(':', `: ${attr_value_std_name ? attr_value_std_name + delimiter : ''}`) }}
        </template>
      </template>
      <!-- 本地尺码 -->
      {{ locationSize(goodsInfo.attr_value_en, 1) }}
    </template>
  </span>  
</template>

<script>
/**
 * goodsInfo 商品数据字段，其中包含size, goods_attr, isOts, sku_sale_attr
 * delimiter 分隔符，例如skuInfoList返回多条属性，将其中的属性分割开来
 * luvlette 本地尺码过滤的品牌名，例如luvlette传入Luvlette将不取本地尺码
 * language 如果skuInfoList为空取goods_attr。 isOts=1的话取多语言库size:,
 * isOts详情： wiki.pageId=285279488
 * 字段详情： wiki.pageId=839228472
 * 
*/
export default {
  props: {
    goodsInfo: {
      type: Object,
      default: () => {
        return {}
      }
    },
    luvlette: {
      type: String,
      default: () => {
        return ''
      }
    },
    language: {
      type: String,
      default: () => {
        return ''
      }
    },
    delimiter: {
      type: String,
      default: () => {
        return '/'
      }
    },
    name: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    // 订单场景专用[旧版]
    isSpecialSize: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    // 订单场景专用[新版]
    isNewSpecialSize: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    onlyShowSize: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    onlyShowGoodsAttr: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    defaultLocalSizeInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    skuInfoList () {
      const { skuInfoList, sku_info_list, sku_sale_attr } = this.goodsInfo || {}
      const list = skuInfoList || sku_info_list || sku_sale_attr || []
      const newList = JSON.parse(JSON.stringify(list))
      if (newList.length) {
        newList.sort((a, b) => {
          return b.is_main - a.is_main
        })
      }
      return newList
    },
    goods_attr () {
      return this.goodsInfo?.goods_attr || ''
    },
    attr_value_std_name () {
      return this.goodsInfo?.attr_value_std_name || ''
    }
  },
  methods: {
    getLocalCountry () {
      let country = ''
      if (typeof window !== 'undefined') {
        country = localStorage.getItem('last_select_country')
      }
      let { countryData = {} } = this.defaultLocalSizeInfo || {}
      let countryStr = countryData?.countryAbbr || ''
      let countryCorrect = {
        GB: 'UK'
      }
      if (country == 'default') country = ''
      country = country ? country : countryCorrect[countryStr] || countryStr

      return country
    },
    localSizeListEvt () {
      let goods_id = this.goodsInfo?.product?.goods_id || this.goodsInfo?.goods_id || ''
      let { defaultLocalSizeList = [] } = this.defaultLocalSizeInfo || {}
      let defaultLocalCountry = this.getLocalCountry()

      if (!defaultLocalSizeList?.length) return []

      let sizeRuleInfo = defaultLocalSizeList?.find(item => item.goods_id == goods_id)
      if (!sizeRuleInfo) return []

      let ruleInfo = sizeRuleInfo?.size_rule_list?.find(item => item.country_code?.toLowerCase() == defaultLocalCountry.toLowerCase())
      return ruleInfo?.rule_list || []
    },
    defaultLocalSizeEvt ({ attr_value_name_en } = {}) {
      let str = ''
      let localSizeList = this.localSizeListEvt()
      if (this.luvlette != 'Luvlette' && localSizeList?.length) {
        // 本地尺码
        localSizeList.forEach(item => {
          if (item.name == attr_value_name_en) {
            str += item.correspond
          }
        })
      }
      return str
    },
    localSizeTextEvt (item = {}) {
      let { defaultLocalSizeType = 1 } = this.defaultLocalSizeInfo || {}
      let localSizeText = this.defaultLocalSizeEvt(item)
      let originSizeText = this.attriButeValue(item)

      if (!localSizeText) return originSizeText
      if ([2].includes(defaultLocalSizeType)) return localSizeText
      if ([3].includes(defaultLocalSizeType)) return originSizeText
      return `${localSizeText}(${originSizeText})`
    },
    locationSize (attr_value_en, type) {
      let str = ''
      const attr_value_location = this.goodsInfo.attr_value_location
      if (this.luvlette != 'Luvlette' && attr_value_location?.size_rule_list?.length) {
        // 本地尺码
        attr_value_location.size_rule_list.forEach(item => {
          if (item.name == attr_value_en) {
            str += type ? `(${item.correspond})` : item.correspond
          }
        })
      }
      return str
    },
    attriButeValue (item) {
      let str = item.attr_std_value ? `${item.attr_value_name}-${item.attr_std_value}` : item.attr_value_name
      if (this.name) {
        str = `${item.attr_value_name}: ${str}`
      }
      return str
    }
  }
}
</script>

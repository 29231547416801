<template>
  <div class="order-cancel-item">
    <LazyMount>
      <s-drawer
        :visible.sync="visible"
        type="full"
        direction="rtl"
        :append-to-body="true"
        :lock-scroll="true"
        :no-header="true"
        :immediately-render="true"
        custom-class="custom-drawer__cancel"
        @open="openDrawer"
      >
        <div class="cancel-drawer-wrap">
          <div class="drawer-wrap-header">
            <s-title-nav
              ref="titleNav"
              :title="titleText"
              :border="false"
            >
              <template slot="preblock">
                <s-title-nav-item
                  icon-class="iconfont-s icons-nav_back"
                  @click.native="handleCloseDrawer"
                />
              </template>
            </s-title-nav>
            <div
              class="order-date order-date-ctn"
              tabindex="0"
              role="text"
            >
              <p class="order">
                {{ htmlDecode(language.SHEIN_KEY_PWA_15209) }}:{{ orderInfo.billno }}
              </p>
              <p class="date">
                {{ htmlDecode(language.SHEIN_KEY_PWA_15190) }}:{{ handlerDate(orderInfo._addTime || orderInfo.addTime) }}
              </p>
            </div>
          </div>
          <div
            v-if="visibleContent"
            class="drawer-wrap-content"
          >
            <keep-alive>
              <component :is="matchCompName" />
            </keep-alive>
          </div>
        </div>
      </s-drawer>
    </LazyMount>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { TitleNav, TitleNavItem, LazyMount } from '@shein/sui-mobile'
import { abtservice } from 'public/src/services/abt'
import { parseQueryString, isFunction } from '@shein/common-function'
import orderLogic from 'public/src/pages/common/orderLogic'
import itemListComp from './comps/itemList.vue'
import refundItemComp from './comps/refundItem.vue'
import codResultComp from './comps/codResult.vue'
import { htmlDecode } from '@shein/common-function'

export default {
  name: 'CancelItemComp',
  components: {
    STitleNav: TitleNav,
    STitleNavItem: TitleNavItem,
    'item-list': itemListComp,
    'refund-item': refundItemComp,
    'cod-result': codResultComp,
    LazyMount
  },
  data() {
    return {
      visibleContent: true // 用于重置drawer中的内容
    }
  },
  computed: {
    ...mapState('cancelItemModule', [
      'orderInfo',
      'language',
      'visibleMainDrawer',
      'matchCompName',
      'compStacks',
      'abtInfo',
      'refundForm'
    ]),
    visible: {
      get() {
        return this.visibleMainDrawer
      },
      set(value) {
        this.handleVisibleMainDrawer(value)
      }
    },
    isCod() {
      return this.orderInfo?.payment_method === 'cod'
    },
    showRefundNew() {
      return this.abtInfo?.newAbtTestConfig?.['refundNew'] !== 'off'
    },
    titleText() {
      if (this.isCod || (!this.isCod && this.showRefundNew)) {
        return this.htmlDecode(this.language.SHEIN_KEY_PWA_14901)
      } else {
        return this.htmlDecode(this.language.SHEIN_KEY_PWA_15873)
      }
    },
  },
  watch: {
    visibleMainDrawer: {
      immediate: true,
      handler(v, ov) {
        v && this.init()
        if (ov && !v) {
          setTimeout(() => {
            this.resetMainState()
            this.visibleContent = false
          }, 1000)
        }
      }
    }
  },
  created() {
    this.getAbtResult()
  },
  methods: {
    ...mapMutations('cancelItemModule', ['assignCancelItemState', 'resetMainState']),
    htmlDecode: text => htmlDecode({ text }),
    handlerDate(date) {
      if (!date) return ''
      return orderLogic.orderDateFormat(date, false)
    },
    openDrawer() {
      this.$nextTick(() => {
        if (isFunction(this.$refs.titleNav?.calcContentWidth)) {
          this.$refs.titleNav.calcContentWidth()
        }
      })
    },
    async getAbtResult() {
      // eslint-disable-next-line @shein-aidc/abt/abt
      const abtInfo = await abtservice.getUserAbtResult({ posKeys: 'OrderlistOldFunc' })
      const abtString = abtInfo?.OrderlistOldFunc?.param || ''
      const newAbtTestConfig = parseQueryString(abtString) || {}
      this.assignCancelItemState({
        abtInfo: {
          ...this.abtInfo,
          newAbtTestConfig
        }
      })
    },
    init() {
      this.visibleContent = true
      if (!this.matchCompName) {
        this.assignCancelItemState({
          matchCompName: 'item-list'
        })
      }
    },
    handleCloseDrawer() {
      const routeStack = this.compStacks?.pop()
      if (routeStack) {
        this.assignCancelItemState({
          matchCompName: routeStack
        })
      } else {
        this.handleVisibleMainDrawer(false)
      }
    },
    handleVisibleMainDrawer(visible) {
      this.assignCancelItemState({
        visibleMainDrawer: !!visible
      })
    }
  }
}
</script>

<style lang="less">
.order-date {
  text-align: center;
  background: #fff;
  .order {
    .font-dpr(32px);
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    line-height: 1.3875em;
  }
  .date {
    .font-dpr(24px);
    font-weight: 400;
    color: rgba(153, 153, 153, 1);
    line-height: 1.875em;
  }
}
.order-date-ctn {
  .order {
    .font-dpr(24px);
    color: #222;
  }
  .date {
    color: #222;
  }
}
.custom-drawer__cancel {
  > .S-drawer__body {
    display: flex;
  }
}
.cancel-drawer-wrap {
  // height: 100%;
  width: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-direction: normal;
  flex-direction: column;
  flex-grow: 1;
  .drawer-wrap-content {
    background: #f6f6f6;
    flex-grow: 1;
    display: flex;
  }
}
</style>

<template>
  <s-drawer
    type="multi"
    direction="btt"
    :max-size="'70%'"
    :append-to-body="true"
    :visible="show"
    @close-from-icon="closeDialogEvt('close')"
  >
    <template slot="top">
      <div>{{ language.SHEIN_KEY_PWA_15714 }}</div>
    </template>
    <div class="relation-order-ctn">
      <div class="relation-order-tips">
        <div class="relation-tips-title">
          {{ language.SHEIN_KEY_PWA_23677 }}
        </div>
        <div class="relation-tips-subtitle">
          {{ isRelationSceneText }}
        </div>
      </div>
      <div
        v-for="list in tempRefundGoodsList"
        :key="list.billno"
        class="relation-order-info"
      >
        <div class="relation-order-title">
          <span>{{ language.SHEIN_KEY_PWA_16822 }} {{ list.billno }}</span>
          <span
            v-if="list.currentOrder"
            class="current-order"
          >
            {{ language.SHEIN_KEY_PWA_14592 }}
          </span>
          <span
            v-if="list.associationOrder"
            class="association-order"
          >
            {{ language.SHEIN_KEY_PWA_21401 }}
          </span>
          <span
            v-if="list.onClickPayOrder"
            class="onClickPay-order"
          >
            {{ language.SHEIN_KEY_PWA_23673 }}
          </span>
        </div>
        <div class="relation-order-goods">
          <div
            v-for="(item, index) in list.goodsList"
            :key="index"
            class="order-goods-box"
          >
            <div class="goods-img">
              <img
                :src="transformImg({ img: item.goods_img })"
                alt=""
              />
            </div>
            <div class="goods-number">
              x{{ item.quantity }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <template
      slot="footer"
    >
      <s-button-group
        :width="'100%'"
        hor
        :num="2"
      >
        <s-button-group-item
          :type="['H72PX']"
          @click="closeDialogEvt('no')"
        >
          {{ language.SHEIN_KEY_PWA_23032 }}
        </s-button-group-item>
        <s-button-group-item
          :type="['primary', 'H72PX']"
          @click="confirmDialogEvt('cancel')"
        >
          {{ language.SHEIN_KEY_PWA_23684 }}
        </s-button-group-item>
      </s-button-group>
    </template>
  </s-drawer>
</template>
<script>
import Vue from 'vue'
import { transformImg, isFunction } from '@shein/common-function'
import { ButtonGroup, ButtonGroupItem } from '@shein/sui-mobile'
;[ButtonGroup, ButtonGroupItem].forEach(c => Vue.use(c))
export default {
  name: 'CancelRelationGoodItem',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    relationGoodsList: {
      type: Array,
      default: () => []
    },
    relationOptions: {
      type: Object,
      default: () => {}
    },
    language: {
      type: Object,
      default: () => {}
    },
    exposeCombinedRefundTip: {
      type: Function,
      default () {
        return () => {}
      }
    }
  },
  computed: {
    tempRefundGoodsList () {
      let { billno } = this.relationOptions
      let list = this.relationGoodsList || []
      // 按订单维度分组
      let listObj = {}
      list?.forEach(item => {
        if (listObj[item.billno]) {
          let hadGoodsIdList = listObj[item.billno]?.goodsList?.map(goods => goods.goods_id)
          if (hadGoodsIdList?.includes(item.goods_id)) {
            listObj[item.billno]?.goodsList?.forEach(goods => {
              if (goods.goods_id == item.goods_id) {
                goods.quantity = Number(goods.quantity) + Number(item.quantity)
              }
            })
          } else {
            listObj[item.billno].goodsList.push(item)
          }
        } else {
          listObj[item.billno] = {
            ...item,
            currentOrder: billno == item.billno,
            associationOrder: item.is_added_order != 1 && billno != item.billno,
            onClickPayOrder: item.is_added_order == 1,
            goodsList: [item]
          }
        }
      })
      return Object.values(listObj) || []
    },
    isRelationSceneText () {
      let isAddOrderScene = this.relationGoodsList?.filter(item => item.is_added_order == 1)?.length == 0
      let isLowValueScene = this.relationGoodsList?.filter(item => item.is_low_value == 1)?.length > 0
      let isRiskPunish = this.relationGoodsList?.some(item => item?.is_risk_punish === 1)

      if (isRiskPunish) return this.language?.SHEIN_KEY_PWA_30954 || ''
      if (isLowValueScene) return this.language?.SHEIN_KEY_PWA_26565 || ''
      if (isAddOrderScene) return this.language?.SHEIN_KEY_PWA_23679 || ''
      return this.language?.SHEIN_KEY_PWA_23678 || ''
    }
  },
  methods: {
    transformImg,
    closeDialogEvt (key = '') {
      let { pageFrom = '' } = this.relationOptions || {}
      const exposeCombinedRefundTip = this.exposeCombinedRefundTip || this.$parent.exposeCombinedRefundTip
      if (isFunction(exposeCombinedRefundTip)) {
        exposeCombinedRefundTip(pageFrom ? '1-19-1-77' : '1-20-1-54', key)
      }
      this.$emit('updateShowValue')
    },
    confirmDialogEvt (key = '') {
      let { type, url, cancelInfo = {}, pageFrom = '' } = this.relationOptions || {}
      const exposeCombinedRefundTip = this.exposeCombinedRefundTip || this.$parent.exposeCombinedRefundTip
      if (isFunction(exposeCombinedRefundTip)) {
        exposeCombinedRefundTip(pageFrom ? '1-19-1-77' : '1-20-1-54', key)
      }
      if ([2].includes(type)) {
        this.$emit('confirmCancelOrder', cancelInfo, key)
      } else if ([3].includes(type)) {
        location.href = url
      } else if ([1].includes(type)) {
        this.$emit('confirmCancelSubmit', cancelInfo)
      }
    }
  }
}
</script>
<style lang="less">
/* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type  */
  .relation-order-ctn{
    padding-bottom: 48/75rem;
    .relation-order-tips{
      padding: 20/75rem 24/75rem;
      font-family: 'SF UI Display';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #767676;
      background: #FFF1D5;
      .relation-tips-title{
        color: #161616;
        font-weight: 700;
        font-size: 14px;
      }
      .relation-tips-subtitle{
        color: #222222;
        font-weight: 400;
        font-size: 12px;
        margin-top: 16/75rem;
      }
    }
    .relation-order-info{
      margin-top: 24/75rem;
      padding-bottom: 36/75rem;
      padding-left: 24/75rem;
      padding-right: 24/75rem;
      border-bottom: 1px solid #E5E5E5;
      &:last-child{
        border-bottom: none;
        padding-bottom: 0;
      }
    }
    .relation-order-title{
      display: flex;
      align-items: center;
      font-family: 'SF UI Display';
      font-style: normal;
      font-weight: 700;
      font-size: 13px;
      line-height: 17px;
      color: #222222;
    }
    .current-order{
      .margin-l(24/75rem);
      background: #F1F8FF;
      border-radius: 1px;
      font-family: 'SF UI Display';
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      color: #2D68A8;
      padding: 8/75rem 4/75rem;
    }
    .association-order{
      .margin-l(24/75rem);
      background: #FFF8EB;
      border-radius: 1px;
      font-family: 'SF UI Display';
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      color: #A86104;
      padding: 8/75rem 4/75rem;
    }
    .onClickPay-order{
      .margin-l(24/75rem);
      background: #F1F8FF;
      border-radius: 1px;
      font-family: 'SF UI Display';
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      color: #2D68A8;
      padding: 8/75rem 4/75rem;
    }
    .relation-order-goods{
      margin-top: 18/75rem;
      display: flex;
      overflow: auto;
      .order-goods-box{
        width: 150/75rem;
        height: 200/75rem;
        .margin-r(24/75rem);
        position: relative;
        &:last-child{
          .margin-r(0);
        }
      }
      .goods-img{
        width: 150/75rem;
        height: 200/75rem;
        img{
          width: 100%;
          height: 100%;
          display: block;
          border: none;
        }
      }
      .goods-number{
        .txt-r();
        padding: 0 6/75rem;
        font-family: 'SF UI Display';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        color: #222222;
        position: absolute;
        background: rgba(255, 255, 255, 0.8);
        .right(0);
        bottom: 0;
      }
    }
  }
</style>
